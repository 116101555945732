<template>
    <div class="index">
        <BannerTop title_blue="Saga" title_white="Explorer"
                   heading="Explore all the Saga's within the AlphaBatem Universe"></BannerTop>

        <div class="container mt-3">
            <div class="row">
                <router-link v-for="item in items" :key="item.id" :to="`/sagas/${item.id}`" class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-4">
                                    <img :src="item.image" class="preview">
                                </div>
                                <div class="col-4">
                                    <h4>{{item.saga_name}}</h4>
                                </div>
                                <div class="col-4">
                                    <h4>Total Supply: {{item.total_supply}}</h4>
                                    <h5>Circulating Supply: 3000</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>

    </div>
</template>

<script>
    import Saga from "@/api/saga"
    import BannerTop from "../../components/BannerTop";

    export default {
        name: "SagaItemIndex",
        components: {BannerTop},
        data() {
            return {
                items: [],
            }
        },
        methods: {
            getIndex: function (saga) {
				Saga.indexItems(saga).then((r) => this.items = r.data);
            }
        },
        mounted() {
            this.getIndex(this.$route.params.id)
        }
    }
</script>

<style scoped>
    .preview {
        max-height: 50px;
        width: auto;
    }

</style>